import "./App.css";
import {useEffect, useState} from "react";

const categories = [
    "Hex Key/Pliers/Screwdriver",
    "Wrench/Spanner",
    "Scissors/Knives/Strippers/Cutters",
    "Saw/Hacksaw/Hammer",
    "Water Gun/Silicon Gun/Scraper",
    "Piece/Drill Bit/Tool Parts",
    "Measuring Tools",
    "Others"
];
const ITEMS_PER_PAGE = 20;

function App() {
    const [isModalOpen, setModalOpen] = useState(false);
    const [activeCategory, setActiveCategory] = useState('All products');
    const [products, setProducts] = useState([]);
    const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
    const [isSearchOpen, setSearchOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);

    const toggleSideMenu = () => {
        setIsSideMenuOpen(prevState => !prevState);
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
          const sideMenu = document.querySelector('.side-menu');
          if (isSideMenuOpen && sideMenu && !sideMenu.contains(event.target)) {
            toggleSideMenu();
          }
    };
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
          document.removeEventListener('mousedown', handleOutsideClick);
        };
      }, [isSideMenuOpen]);

    const [itemsToShow, setItemsToShow] = useState(ITEMS_PER_PAGE);
    const loadMoreItems = () => {
        setItemsToShow(prevItemsToShow => prevItemsToShow + ITEMS_PER_PAGE);
    };


    useEffect(() => {
        fetchProducts('All products');
    }, []);

    const fetchProducts = (category = '') => {
        let url = 'https://www.conantoolsny.com/products';
        if (category && category !== 'All products'){
            const encodedCategory = encodeURIComponent(category);
            url += `?category=${encodedCategory}`;
        }
        fetch(url)
            .then(response => response.json())
            .then(data => setProducts(data))
            .catch(error => console.error('Error fetching products:', error));
    };

    const handleCategoryClick = (category) => {
        setActiveCategory(category);
        fetchProducts(category);
    };

    const handleSideClick = (category) => {
        setActiveCategory(category);
        fetchProducts(category);
        toggleSideMenu();
    };


    const openWechat = () => {
        setModalOpen(true);
    }

    const closeModal = (e) => {
        if (e.target.id === "outside-modal"){
            setModalOpen(false);
        }
    };

    const openSearch = () => {
        setSearchOpen(true);
    }

    const closeSearch = () => {
        setSearchOpen(false);
        setFilteredProducts([]);
        setSearchQuery("");
    }

    const handleSearch = (event) => {
        getFilteredProducts();
    };

    const handleKeyPress = (event) =>{
        if (event.key === 'Enter') {
            getFilteredProducts();
        }
    }

    const getFilteredProducts = () =>{
        if (!searchQuery.trim()){
            setFilteredProducts([]);
        }
        else{
            const userInput = searchQuery.toLowerCase();
            fetchProducts('All products');
            const filtered = products.filter(product =>
                product.english_description.toLowerCase().includes(userInput) ||
                product.chinese_description.toLowerCase().includes(userInput) ||
                product.item_no.toLowerCase().includes(userInput)
            );
            setFilteredProducts(filtered);
            console.log(filteredProducts);
        }
    };

    return (
        <div className="Home-Page">
            <div className="Home-Page-header">
                <p>Conan Tools, Only High Quality!</p>
            </div>
            <div className="Home-Page-banner">
                <div className="Banner-Image">
                    <div onClick={toggleSideMenu} className={`menu-toggle ${isSideMenuOpen ? 'hidden' : ''}`}>
                        ☰
                    </div>
                    <img src="/images/company_icon.png" alt="icon" className="banner-img"></img>
                    <img src="/images/search.png" alt="search" className="Home-Search" onClick={openSearch}></img>
                </div>
                <div className={`side-menu ${isSideMenuOpen ? 'open' : 'closed'}`}>
                    <div className="Side-Menu-Close" onClick={toggleSideMenu}>
                        x
                    </div>
                    <img src="/images/company_icon.png" alt="icon"></img>
                    {['All products', ...categories].map((category) => (
                        <div
                            key={category}
                            className={`side-item ${activeCategory === category ? 'active' : ''}`}
                            onClick={() => handleSideClick(category)}
                        >
                            {category}
                        </div>
                    ))}
                </div>
                <div className="Home-Page-Banner-Bottom">
                    <div className="Product-nav-bar">
                        {['All products', ...categories].map((category) => (
                            <div
                                key={category}
                                className={`nav-item ${activeCategory === category ? 'active' : ''}`}
                                onClick={() => handleCategoryClick(category)}
                            >
                                {category}
                            </div>
                        ))}
                    </div>
                    <img src="/images/search.png" alt="search" className="search" onClick={openSearch}></img>
                </div>
                {isSearchOpen && (
                    <div className="Search-Page">
                        <div className="Search-Container">
                            <div className="Search-Close" onClick={closeSearch}>
                                x
                            </div>
                            <div className="Search-Input-Container">
                                <p className="Search-Title">Find Product</p>
                                <div className="search-box">
                                    <input
                                        type="text"
                                        placeholder="I'm Looking for..."
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                        className="search-input"
                                    />
                                    <i className="search-icon" onClick={handleSearch}></i>
                                </div>
                            </div>
                            <div className="Search-Display-Container">
                                {searchQuery.trim() ? (
                                    filteredProducts.length > 0 ? (
                                    <>
                                        <div className="Search-Display-Count">
                                            <p className="Display-Count-Total">Total</p>
                                            <p className="Display-Count-Number">{filteredProducts.length}</p>
                                            <p className="Display-Count-Total">products</p>
                                        </div>
                                        {filteredProducts.slice(0, itemsToShow).map(product => (
                                            <div key={product.item_no} className="product">
                                                <div className="product-image">
                                                    <img src={`/images/${product.item_no}.png`} alt={product.english_description} />
                                                </div>
                                                <div className="product-info">
                                                    <h4>{product.english_description}</h4>
                                                    <p>{product.chinese_description}</p>
                                                    <p className="product-code">{product.item_no}</p>
                                                </div>
                                            </div>
                                          ))}
                                        <div className="More-Products">
                                            {filteredProducts.length > itemsToShow && (
                                                <button className="load-more" onClick={loadMoreItems}>View More</button>
                                            )}
                                        </div>
                                    </>
                                    ) : (
                                        <p>No such products exist.</p>
                                        )
                                    ):null
                                }
                            </div>
                        </div>
                    </div>
                )}

            </div>
            <div className="Home-Page-Container">
                <div className="Display—Header">
                    <h1>{activeCategory}</h1>
                </div>
                <div className="Display-Count">
                    <p className="Display-Count-Total">Total</p>
                    <p className="Display-Count-Number">{products.length}</p>
                    <p className="Display-Count-Total">products</p>
                </div>
                <div className="Home-Page-Display-Container">
                    <>
                        {products.slice(0, itemsToShow).map(product => (
                            <div key={product.item_no} className="product">
                                <div className="product-image">
                                    <img src={`/images/${product.item_no}.png`} alt={product.english_description} />
                                </div>
                                <div className="product-info">
                                    <h4>{product.english_description}</h4>
                                    <p>{product.chinese_description}</p>
                                    <p className="product-code">{product.item_no}</p>
                                </div>
                            </div>
                          ))}
                        <div className="More-Products">
                            {products.length > itemsToShow && (
                                <button className="load-more" onClick={loadMoreItems}>View More</button>
                            )}
                        </div>
                    </>
                </div>
                <footer className="Home-Page-Footer">
                    <div className="Home-Page-Footer-Top">
                        <div className="Footer-Top-Left">
                            <div className="Footer-section">
                                <h3>Business Address</h3>
                                <p>47-10A Grand Ave, Queens, NY 11378</p>
                            </div>
                            <div className="Footer-section">
                                <h3>Company Name</h3>
                                <p>Conan Tools & Hardware Trading INC</p>
                            </div>
                            <div className="Footer-section">
                                <h3>Operating Hours</h3>
                                <p>9:30AM - 5:00PM</p>
                            </div>
                        </div>
                        <div className="Footer-Top-Right">
                            <div className="Footer-section">
                                <h3>Contact Us</h3>
                            </div>
                            <div className="Footer-section">
                                <p>917.914.1028</p>
                            </div>
                            <div className="Footer-section">
                                <p>conantoolsusa@gmail.com</p>
                            </div>
                        </div>
                    </div>
                    <div className="Home-Page-Footer-Bottom">
                        <div className="Footer-Bottom-Left">
                            <img src="/images/company_icon.png" className="footer-logo" alt="hi"></img>
                        </div>
                        <div className="Footer-Bottom-Right">
                            <div className="contact-icons">
                                <a href="https://wa.me/9179141028" target="_blank" rel="noopener noreferrer"><img src="/images/whatsapp.png" alt="WhatsApp" /></a>
                                <img src="/images/wechat.png" alt="WeChat" onClick={openWechat}/>
                                <a href="mailto:conantoolsusa@gmail.com" title="Send Email"><img src="/images/email.png" alt="Email" /></a>
                            </div>
                            {isModalOpen && (
                                <div id="outside-modal" className="modal" onClick={closeModal}>
                                    <div className="modal-content">
                                        <img src="/images/wechatQR.jpg" alt="WeChat QR Code" />
                                    </div>
                                </div>
                            )}
                            <p>&copy; 2024 Conan Tools & Trading INC. All rights reserved.</p>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    );
}

export default App;
